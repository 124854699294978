import React, { useState, useEffect } from "react";
import "./Navbar.scss";
import { Sling as Hamburger } from "hamburger-react";

function Navbar() {
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  useEffect(() => {
    const openHamburgerMenu = () => {
      document.addEventListener("click", closeHamburgerMenu);
    };

    const closeHamburgerMenu = () => {
      setShowHamburgerMenu(false);
      document.removeEventListener("click", closeHamburgerMenu);
    };

    showHamburgerMenu ? openHamburgerMenu() : closeHamburgerMenu();
  }, [showHamburgerMenu]);

  return (
    <div className="navBackground">
      <div className="nav px-4 py-2 px-sm-5 py-sm-3">
        <h2 className="nav--left">&lt;LR&gt;</h2>
        <Hamburger toggled={showHamburgerMenu} toggle={setShowHamburgerMenu} />

        {/* Below ui for mobile */}
        <ul
          id="fade-in"
          className={
            showHamburgerMenu
              ? "nav__list-mobile only-mobile show"
              : "nav__list-mobile only-mobile"
          }
          // onClick={closeHamburgerMenu}
        >
          <a href="#Top">
            <li className="nav__list-mobile--home list">Top</li>
          </a>
          <a href="#skillsSection">
            <li className="nav__list-mobile--skills list">Skills</li>
          </a>
          <a href="#aboutMeSection">
            <li className="nav__list-mobile--aboutMe list">About</li>
          </a>
          <a href="#projectsSection">
            <li className="nav__list-mobile--projects list">Projects</li>
          </a>
          <a href="#ContactSection">
            <li className="nav__list-mobile--contact list">Contact</li>
          </a>
        </ul>
        {/* Below ui for tablet and desktop */}
        {/* <ul className="nav__list only-tablet-desk"> */}
        <ul id="fade-in" className="nav__list only-tablet-desk">
          <a href="#Top">
            <li className="nav__list--home list">Top</li>
          </a>
          <a href="#skillsSection">
            <li className="nav__list--skills list">Skills</li>
          </a>
          <a href="#aboutMeSection">
            <li className="nav__list--aboutMe list">About</li>
          </a>
          <a href="#projectsSection">
            <li className="nav__list--projects list">Projects</li>
          </a>
          <a href="#ContactSection">
            <li className="nav__list--contact list">Contact</li>
          </a>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
