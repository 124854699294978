import React from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import phone from "../../assets//image/phone.jpg";
import "./Contact.scss";

const customId = "contact-toast";

function Contact() {
  const handleSubmit = (evt) => {
    evt.preventDefault();
    const id = toast.loading("Please wait...", { toastId: customId });
    emailjs
      .sendForm(
        `${process.env.REACT_APP_EMAIL_JS_SERVICE_ID}`,
        `${process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID}`,
        evt.target,
        `${process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY}`
      )
      .then(
        (result) => {
          evt.target.reset();
          toast.update(id, {
            render: "Email Sent!!!",
            type: "success",
            theme: "colored",
            autoClose: 3000,
            closeOnClick: true,
            closeButton: true,
            isLoading: false,
          });
        },
        (error) => {
          toast.update(id, {
            render:
              "There was an Error sending the email, please try agin later",
            type: "error",
            theme: "colored",
            autoClose: 3000,
            closeOnClick: true,
            closeButton: true,
            isLoading: false,
          });
        }
      );
  };

  return (
    <div id="ContactSection" className="px-4 py-4 px-sm-5 py-sm-3">
      <img src={phone} alt="issue_loading_phone_picture" className="phone" />
      <div className="contactMe">
        <h1 className="contactMeTitle">Contact</h1>
        <form className="form" onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email address
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              aria-describedby="emailHelp"
              name="email"
              placeholder="Please enter your email address"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Please enter your name"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="message" className="form-label">
              Message
            </label>
            <textarea
              className="form-control"
              id="message"
              name="message"
              rows="3"
              placeholder="Please enter your message here"
              required
            ></textarea>
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
