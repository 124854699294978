import React from "react";
import "./SkillCard.scss";

function Skills({ icon, skill }) {
  return (
    <div className="skill px-2 py-2 px-sm-3 py-sm-3">
      <img className={`icon ${skill}`} src={icon} alt={skill} />
      <p className="icon--name">{skill}</p>
    </div>
  );
}

export default Skills;
