import React from "react";
import "./ProjectCard.scss";
import data from "../../data/projects.json";
import projectImage from "../../assets/image/projects.png";

function ProjectCard({ title, image, tech }) {
  const projects = data.map((project, idex) => {
    return (
      <div className="card">
        <div
          key={project.id}
          className={`projectCard ${project.className}`}
        ></div>
        <div className="card-body">
          <h2 className="projectCard--title">{project.title}</h2>
          <p className="projectCard-description">{project.description}</p>
        </div>
        <div className="card-footer">
          <h4 className="projectCard--techTitle">Technologies used:</h4>
          <div className="projectCard__tech">
            {project.technology.map((tech) => {
              return <span className="projectCard__tech--span">{tech}</span>;
            })}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="projectBackground">
      <div id="projectsSection" className="px-4 py-4 px-sm-5 py-sm-3 projects">
        <img
          src={projectImage}
          alt="issue_loading_projects_pic"
          className="projectsImage"
        />
        <div className="projects">
          <h1 className="projects-title">Projects</h1>
          <div className="projects">{projects}</div>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
