import React from "react";
import "./Hero.scss";
import HTMLIcon from "./../../assets/icons/icons8-html-5.svg";
import SassIcon from "../../assets/icons/icons8-sass.svg";
import JavascriptIcon from "../../assets/icons/icons8-javascript.svg";
import ReactIcon from "../../assets/icons/icons8-react-native.svg";
import NodeIcon from "../../assets/icons/icons8-nodejs.svg";
import ExpressIcon from "../../assets/icons/express-109.svg";
import MongodbIcon from "../../assets/icons/icons8-mongodb.svg";
import MongooseIcon from "../../assets/icons/mongoose.svg";
import SocketIoIcon from "../../assets/icons/socket-io.svg";
import SkillCard from "../SkillCard/SkillCard";
import Rails from "../../assets/icons/red-ruby-on-rails.svg";
import Ruby from "../../assets/icons/icons8-ruby-programming-language-240.svg";
import Mariadb from "../../assets/icons/icons8-mariadb.svg";

function Hero() {
  return (
    <div className="hero px-4 py-4 px-sm-5 py-sm-3">
      <div className="profile-container">
        <h1 className="profile-title fw-bold">Lenin Rivera</h1>
        <h3 className="profile-desc">- Full-Stack Web Developer</h3>
      </div>
      <h2 id="skillsSection" className="skills-title fw-bold">
        Skills
      </h2>
      <div className="skills-container">
        <SkillCard icon={HTMLIcon} skill="HTML" />
        <SkillCard icon={SassIcon} skill="Sass" />
        <SkillCard icon={JavascriptIcon} skill="Javascript" />
        <SkillCard icon={ReactIcon} skill="React.js" />
        <SkillCard icon={NodeIcon} skill="Node.js" />
        <SkillCard icon={ExpressIcon} skill="Express.js" />
        <SkillCard icon={MongodbIcon} skill="MongoDB" />
        <SkillCard icon={MongooseIcon} skill="Mongoose.js" />
        <SkillCard icon={SocketIoIcon} skill="Socket.io" />
        <SkillCard icon={Ruby} skill="Ruby" />
        <SkillCard icon={Rails} skill="Rails" />
        <SkillCard icon={Mariadb} skill="Mariadb" />
      </div>
    </div>
  );
}

export default Hero;
