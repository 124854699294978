import React from "react";
import coffeeIsGood from "../../assets/image/coffee_is_a_good_idea.jpg";
import "./AboutMe.scss";

function AboutMe() {
  return (
    <div id="aboutMeSection" className="px-4 py-4 px-sm-5 py-sm-3">
      <img
        src={coffeeIsGood}
        alt="issue_loading_coffee_pic"
        className="coffeeIsGood"
      />
      <div className="aboutMe">
        <h1 className="fw-bold aboutMeTitle">
          Hi, I'm Lenin. Nice to meet you!
        </h1>
        <p>
          I'm a versatile web developer with a passion for crafting seamless
          digital experiences. With 1.5 years of hands-on experience in manual
          QA testing, I've honed my attention to detail and knack for
          identifying potential issues. Transitioning into a Rails developer
          role, I've spent 0.5 years bringing dynamic web applications to life.
          My unique blend of QA expertise and Rails proficiency allows me to
          create user-centric, robust, and bug-free websites. Let's collaborate
          to turn your ideas into functional and polished online solutions!
        </p>
      </div>
    </div>
  );
}

export default AboutMe;
