import React from "react";
import "./Footer.scss";
import { FaLinkedin } from "react-icons/fa";
import { FaGithubSquare } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

function Footer() {
  return (
    <div className="footerBackground">
      <div className="footer px-4 py-2 px-sm-5 py-sm-3">
        <h2 className="footer--left">&lt;/LR&gt;</h2>
        <div className="footer--icons">
          <a href="https://www.linkedin.com/in/lenin-rivera-047137187/">
            <FaLinkedin className="icon" />
          </a>
          <a href="https://github.com/LeninRivera">
            <FaGithubSquare className="icon" />
          </a>
          <a href="mailto:leninra24@gmail.com">
            <MdEmail className="icon" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
