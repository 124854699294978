import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Hero from "../components/Hero/Hero";
import ProjectCard from "../components/ProjectCard/ProjectCard";
import Contact from "../components/Contact/Contact";
import AboutMe from "../components/AboutMe/AboutMe";
import Footer from "../components/Footer/Footer";
//TODO: Add an about me section
//TODO: Add Modal with Bootstrap for project
//TODO: Create a Modal component in which you can pass a projects information.

function home() {
  return (
    <div>
      <Navbar />
      <Hero id="heroSection" />
      <AboutMe />
      <ProjectCard />
      <Contact />
      <Footer />
    </div>
  );
}

export default home;
